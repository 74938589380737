import { faCheck, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type AnyObject } from '@Types';
import clsx from 'clsx';
import { useMemo, type FC } from 'react';
import { useExpandableContainer } from 'ExpandableContainer';
import { type RowText, type RowData } from './Grades.types';

type RowProps = {
    rowData: RowData;
    mobileClassName?: string;
    desktopClassName?: string;
};

const classes = {
    desktop: {
        container: clsx([
            'hidden',
            'grid-cols-[repeat(70,_minmax(0,_1fr))]',
            'min-h-[26px]',
            'text-xxs',
            'sm:grid',
            'border-t',
            'border-solid',
            'border-[#D4D4D4]',
            'items-center',
        ]),
        columnItem: {
            0: clsx(['py-[6.5px]', 'col-[2_/4]']),
            1: clsx(['py-[6.5px]', 'col-[4_/_29]']),
            2: clsx(['py-[6.5px]', 'col-[30_/_54]']),
            3: clsx(['py-[6.5px]', 'col-[55_/_65]']),
            4: clsx(['py-[6.5px]', 'col-[66_/_70]']),
        } as AnyObject<string>,
    },
    mobile: {
        container: clsx([
            'flex',
            'min-h-[29px]',
            'leading-normal',
            'w-full',
            'justify-between',
            'text-xxs',
            'sm:hidden',
            'hover:cursor-pointer',
            'border-t',
            'border-solid',
            'border-[#D4D4D4]',
        ]),
        open: {
            container: clsx(['flex', 'flex-auto', 'flex-col pt-1.5']),
            row: clsx(['flex', 'justify-between']),
            label: clsx(['flex-[0_0_88px]', 'font-semibold']),
            text: clsx(['flex-[1_0_150px]', 'text-right', 'leading-none']),
        },
        closed: clsx(['flex', 'flex-auto', 'items-center', 'justify-between', 'pe-[2px]']),
    },
    chevron: {
        container: clsx(['flex', 'h-[28px]', 'flex-[0_0_30px]', 'flex-col', 'items-center', 'justify-center']),
        icon: clsx(['text-[16px]', 'hover:text-blue', 'transition-transform', 'duration-[250]ms']),
    },
};

const mobileHeaderOptions = ['SMARTCASE', 'Exam Course', 'Project', 'Title'] as const;

const DesktopRow: FC<{
    columns: (JSX.Element | string | null)[];
    started?: boolean;
    className?: string;
}> = ({ columns, className, started }) => (
    <div
        className={clsx(
            classes.desktop.container,
            className,
            { 'text-beige-beyond-dark': !started },
            { 'text-black': started },
        )}
    >
        {columns.map((title, i) =>
            title !== null ? (
                <div
                    key={typeof title === 'string' ? title : `check:${i}`}
                    className={clsx([classes.desktop.columnItem[i], 'flex', 'break-words'])}
                >
                    {title}
                </div>
            ) : null,
        )}
    </div>
);

const MobileRow: FC<{
    rows: RowText[];
    headerIndex: number;
    className?: string;
    started?: boolean;
}> = ({ rows, headerIndex, started, className }) => {
    const { open, ExpandableContainer, toggleOpen, handleKeyDown } = useExpandableContainer();

    return (
        <ExpandableContainer
            className={clsx(
                classes.mobile.container,
                className,
                { 'text-beige-beyond-dark': !started },
                { 'text-black': started },
            )}
            onClick={toggleOpen}
            onKeyDown={handleKeyDown}
        >
            {open ? (
                <div className={classes.mobile.open.container}>
                    {rows.map(([l, t]) => (
                        <div className={classes.mobile.open.row} key={l}>
                            <div className={classes.mobile.open.label}>{l}</div>
                            {t && <div className={classes.mobile.open.text}>{t}</div>}
                        </div>
                    ))}
                </div>
            ) : (
                <div className={classes.mobile.closed}>
                    <div>{rows?.[headerIndex]?.[1]}</div>
                    <div className="ms-1">{rows.at(-1)?.[1]}</div>
                </div>
            )}
            <div className={classes.chevron.container}>
                <FontAwesomeIcon
                    className={clsx(classes.chevron.icon, { 'rotate-180': open }, { 'rotate-0': !open })}
                    icon={faChevronDown}
                    color={open ? '#4C79FF' : '#A9A197'}
                />
            </div>
        </ExpandableContainer>
    );
};

const useBuildRowInfo = ({ complete, rowTextList }: RowData) => {
    const [desktopColumns, mobileRows, mobileHeaderIndex] = useMemo(() => {
        if (!rowTextList || !rowTextList.length) return [[] as [], [] as [], 0];
        const desktop = rowTextList.reduce<(string | JSX.Element | null)[]>(
            (prev, curr, i) => {
                if (i === 1 && rowTextList.length === 3) return [...prev, null, curr.at(-1) ?? null];
                return [...prev, curr.at(-1) ?? null];
            },
            complete ? [<FontAwesomeIcon icon={faCheck} />] : [null],
        );

        const mobileIndex = rowTextList.findIndex(([label]) =>
            mobileHeaderOptions.map(v => v.toLowerCase()).includes(String(label).toLowerCase()),
        );

        const mobile = rowTextList.map(r => [r.at(-2), r.at(-1)] as RowText);

        return [desktop, mobile, mobileIndex];
    }, [complete, rowTextList]);

    return { desktopColumns, mobileRows, mobileHeaderIndex };
};

export const Row: FC<RowProps> = ({ rowData, desktopClassName, mobileClassName }) => {
    const { desktopColumns, mobileRows, mobileHeaderIndex } = useBuildRowInfo(rowData);

    if (!desktopColumns.length || !mobileRows.length) return null;

    return (
        <>
            <DesktopRow columns={desktopColumns} started={rowData.started} className={desktopClassName} />
            <MobileRow
                rows={mobileRows}
                className={mobileClassName}
                started={rowData.started}
                headerIndex={mobileHeaderIndex}
            />
        </>
    );
};
