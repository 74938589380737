
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.grades = window.Smartly.locales.modules.en.back_royal.grades || {};
window.Smartly.locales.modules.en.back_royal.grades.grades = window.Smartly.locales.modules.en.back_royal.grades.grades || {};
window.Smartly.locales.modules.en.back_royal.grades.grades = {...window.Smartly.locales.modules.en.back_royal.grades.grades, ...{
    "policy1": "Your program’s grading policy can be accessed from ",
    "policy2": "For additional assistance, contact the academic advising team at ",
    "policy3": " or learn more ",

    "faq": "FAQ",
    "resources": "Resources",
    "here": "here",
    "period": ".",

    "coreCurriculum": "Core Curriculum",
    "exams": "Exams",
    "project": "Project",
    "projects": "projects",
    "other": "Other Requirements",
    "specializations": "Specializations",
    "optional": "Optional",
    "electives": "Electives",

    "course": "Course",
    "completed": "Completed",
    "dateCompleted": "Date Completed",
    "score": "Score",
    "examCourse": "Exam Course",
    "rubricScore": "Rubric Score",
    "status": "Status",
    "title": "Title",

    "refundEligibility": "Refund Eligibility",
    "refund1": "For your convenience, we have listed below the total number of assessments that you must complete in order to graduate from the program, as well as the number of assessments you have completed so far. Please review the ",
    "refund2": "for additional information. ",
    "sinceBeginning": "Since beginning coursework in your program, you have completed:",
    "of": " of ",
    "requiredAssessments": "required assessments",
    "note1": "Note: SMARTCASEs in Electives are not included in the refund calculation. See the ",
    "note2": "for details of how SMARTCASEs in Specializations are included in the refund policy calculation.",
    "refundPolicy": "refund policy",
    "total": "Total: ",
    "refund_eligibility_thresholds": "Refund Eligibility Thresholds",
    "table_heading_assessment_range": "Assessment Range",
    "table_heading_percent_refunded": "% Tuition Refunded",
    "assessment_range": "{from} to {to}",
    "thresholds_footer_note": "Students who withdraw prior to beginning any of the lessons, concentrations, specializations, or projects listed in the catalog will be refunded 100% of tuition.",
    "foundationsNote": "Grades earned in {title} or Open Courses do not count toward your final score calculation."
}
}
    