/* eslint-disable react/jsx-wrap-multilines */
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { isEqual } from 'lodash/fp';
import { Fragment, memo, useMemo, type FC } from 'react';
import { ExpandableCardList } from 'ExpandableContainer';
import { type Section } from './Grades.types';
import { PlaylistBreakdown } from './PlaylistBreakdown';

type CardProps = {
    imageSrc: string;
    title: string;
    sections: Section[];
    started?: boolean;
    completed?: boolean;
};

const classes = {
    container: clsx([
        'flex',
        'justify-between',
        'items-center',
        'h-14',
        'ms-[15px]',
        'me-[15px]',
        'sm:me-[30px]',
        'bg-white',
        'hover:cursor-pointer',
    ]),
    titleBox: clsx(['flex', 'h-[30px]', 'items-center']),
    image: clsx(['h-[30px]', 'w-[30px]', 'rounded-full']),
    title: {
        primary: clsx(['inline-block', 'ms-2.5', 'text-xs', 'min-[365px]:text-base', 'font-semibold']),
        secondary: clsx(['inline-block']),
    },
    checkIcon: clsx(['ms-[5px]', 'h-[14px]', 'w-[14px]', 'flex-shrink-0', 'inline-block', 'align-[-.125em]']),
    chevronClasses: clsx(['w-[30px]', 'sm:w-auto', 'hover:text-blue', 'transition-transform', 'duration-[250ms]']),
    playlistBreakdown: clsx(['transition-opacity', 'duration-[300ms]']),
};

export const PlaylistCard: FC<CardProps> = memo(({ imageSrc, title, sections, completed, started }) => {
    // This allows us to split the last word from the title and combine it with the check icon when we render it
    // which means we can force the last word and the icon to wrap at the same time so we don't have a solo icon wrapped to the next line
    const formattedTitle = useMemo(
        () =>
            completed
                ? title.split(' ').reduce(
                      (prev, curr, i, arr) => {
                          if (i !== arr.length - 1) {
                              prev[0] = prev[0].concat(curr, ' ');
                          } else prev[1] = curr;

                          return prev;
                      },
                      [''],
                  )
                : title,
        [completed, title],
    );

    return (
        <ExpandableCardList
            header={
                <>
                    <img src={imageSrc} alt={title} className={classes.image} />
                    <div
                        className={clsx(
                            classes.title.primary,
                            { 'text-black': started },
                            { 'text-beige-beyond-dark': !started },
                        )}
                    >
                        {Array.isArray(formattedTitle)
                            ? formattedTitle.map((str, i) => {
                                  if (i !== formattedTitle.length - 1) return <Fragment key={str}>{str}</Fragment>;

                                  return (
                                      <div className={classes.title.secondary} key={str}>
                                          {str}
                                          {!!completed && (
                                              <FontAwesomeIcon
                                                  icon={faCircleCheck}
                                                  className={classes.checkIcon}
                                                  color="#0DD037"
                                              />
                                          )}
                                      </div>
                                  );
                              })
                            : title}
                    </div>
                </>
            }
            body={<PlaylistBreakdown sections={sections} />}
        />
    );
}, isEqual);
