/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import { type FC } from 'react';
import { type RowData, type Section } from './Grades.types';
import { Row } from './Row';

type Props = {
    sections: Section[];
};

const classes = {
    container: clsx(['px-3.5', 'pb-5', 'pt-3.5']),
    section: {
        container: clsx(['mb-4', 'last:mb-0', 'text-black']),
        header: {
            desktop: {
                h4: clsx(['hidden', 'sm:block', 'text-[14px]']),
                row: clsx(['border-none', 'min-h-0', 'font-semibold']),
            },
            mobile: clsx(['text-[14px]', 'font-semibold', 'mb-3', 'flex', 'justify-center', 'sm:hidden']),
        },
    },
};

export const PlaylistBreakdown: FC<Props> = ({ sections }) => (
    <div className={classes.container}>
        {sections.map(
            ({ title, rowsData }) =>
                !!rowsData?.length && (
                    <div className={classes.section.container} key={title}>
                        {sections.length > 1 && <h4 className={classes.section.header.mobile}>{title}</h4>}
                        <h4 className={classes.section.header.desktop.h4}>
                            <Row
                                rowData={{
                                    started: true,
                                    rowTextList: rowsData?.[0]?.rowTextList.map(([l, v]) => [
                                        v,
                                        l,
                                    ]) as RowData['rowTextList'],
                                }}
                                desktopClassName={classes.section.header.desktop.row}
                            />
                        </h4>
                        {rowsData?.map((rowData, i) => (
                            <Row rowData={rowData} key={i} />
                        ))}
                    </div>
                ),
        )}
    </div>
);
